import React from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { generateArticleStructuredData } from 'src/lib/utils';

// components
import { CmsComponentSwitch } from 'src/cms/CmsComponentSwitch';
import { TemplateWrapper } from 'src/templates';
import { ContentfulToolbar } from 'src/components/ContentfulToolbar';

// helpers
import { CmsComponents, ContentfulTemplates } from 'src/lib/constants';
import {
  ArticlePageHeader,
  ArticleTOC,
} from 'src/components/headers/ResourceArticlePageHeader';
import styles from './ArticleTemplate.module.scss';

const ArticleTemplateScripts = dynamic(
  () => {
    return import(
      'src/templates/Resources/ArticleTemplate/ArticleTemplateScripts'
    );
  },
  {
    ssr: false,
  },
);

const ArticleTemplate = ({ fields, ...props }) => {
  const { route, asPath } = useRouter();
  const anchors = fields?.components?.filter((component) => {
    return component.sys?.contentType?.sys?.id === CmsComponents.ANCHOR;
  });

  const authors = fields?.authors?.filter((author) => {
    return author?.sys?.type === 'Entry';
  });

  return (
    <TemplateWrapper
      meta-title={fields?.metaTitle}
      meta-description={fields?.metaDescription}
      hasFooterCta={false}
      className={styles.resourceArticle}
      openGraphImage={fields?.openGraphImage?.fields?.file?.url}
      structuredData={generateArticleStructuredData({
        authorName: authors
          ?.map((a) => {
            return a.fields.name;
          })
          .join('; '),
        datePublished: fields?.published,
        dateModified: fields?.updated || fields?.published,
        headline: fields?.metaTitle,
        image: fields.bannerImage?.fields?.file?.url,
      })}
    >
      <div data-testid={ContentfulTemplates.ARTICLE}>
        <ArticlePageHeader
          {...fields}
          title={fields?.metaTitle}
          date={fields?.published}
          updated={fields?.updated}
          authors={authors}
          path={asPath}
        />
        {anchors?.length > 0 && <ArticleTOC anchors={anchors} />}
        <CmsComponentSwitch
          templatePage={ContentfulTemplates.ARTICLE}
          components={fields?.components}
        />
      </div>
      <ArticleTemplateScripts route={route} {...props} />
      <ContentfulToolbar />
    </TemplateWrapper>
  );
};

export default ArticleTemplate;
