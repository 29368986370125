import React, { useState } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import { TemplateWrapper } from 'src/templates';
import { CmsComponentSwitch } from 'src/cms/CmsComponentSwitch';
import { CmsThemes, ContentfulTemplates, RESOURCES_PATH } from 'src/lib/constants';
import { createSearchDropdown } from 'src/components/Inputs/SearchBar';
import { ContentfulToolbar } from 'src/components/ContentfulToolbar';

const HubTemplateScripts = dynamic(
  () => {
    return import('src/templates/HubTemplate/HubTemplateScripts');
  },
  {
    ssr: false,
  },
);

const HubTemplate = ({
  fields,
  events,
  noIndex = false,
  topics = [],
  totalResources = 0,
  cmsTheme = 'web 2.0',
  ...props
}) => {
  const { route } = useRouter();
  const [searchTopic, setSearchTopic] = useState();

  // creates an object of all the topics ex. {ach:{topic:'ACH'}, api:{topic:'API'}, 'all topics': {topic:{..}}, banking:{topic:'Banking'}}
  const topicsObj = topics.reduce((obj, e) => {
    if (typeof e === 'object') {
      obj = { ...obj, [e.label.toLowerCase()]: { topic: e } };
      return obj;
    } else {
      obj = { ...obj, [e.toLowerCase()]: { topic: e } };
      return obj;
    }
  }, {});

  // Arranges the topics in alphabetical order based on the keys of the topicsObj ex. ['ACH',{..},'API','Banking']
  const sortedTopics = Object.keys(topicsObj)
    .sort()
    .map((e) => {
      return topicsObj[e].topic;
    });
  topics = sortedTopics;

  // eslint-disable-next-line no-console
  const handleChange = (search) => {
    search?.selectedOption &&
      setSearchTopic(search.selectedOption.toLowerCase());
  };

  const topicsDropdown = createSearchDropdown({
    label: 'Topics',
    options: topics,
    defaultOption: 'All Topics',
  });

  totalResources = props.allTopicArticles?.length || totalResources;
  const resultType = 'resource';
  const search = {
    placeholder: 'Search',
    destination: `${RESOURCES_PATH}${
      searchTopic ? `/${searchTopic}` : ''
    }/search`,
    resultType,
    onChange: handleChange,
    label: `${totalResources} ${resultType}${
      totalResources !== 1 ? 's' : ''
    } available`, // ex: 2 resources available
    dropdowns: [topicsDropdown],
  };

  return (
    <TemplateWrapper
      meta-title={fields?.metaTitle}
      meta-description={fields?.metaDescription}
      hasFooterCta={false}
      noIndex={fields?.metaNoindex || noIndex}
      className={fields?.hubType === 'Events' ? 'event-hub' : 'hub'}
      cmsTheme={cmsTheme}
    >
      <div data-testid={ContentfulTemplates.HUB}>
        {cmsTheme === CmsThemes.WEB3 && <div className='nav-spacer' />}

        <CmsComponentSwitch
          components={fields?.components}
          events={events}
          search={topics.length > 0 && search}
          templatePage={ContentfulTemplates.HUB}
        />
      </div>
      <HubTemplateScripts route={route} {...props} />
      <ContentfulToolbar />
    </TemplateWrapper>
  );
};

export default HubTemplate;
