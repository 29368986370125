import React from 'react';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';

// components
import { MainNav } from 'src/components/navigation';
import { MainMenu } from 'src/sections/Navigation';
import { ConsentManager } from 'src/containers/ConsentManager';
import Footer from 'src/components/footer';
import { Banner, UnidentifiedTraffic } from 'src/components/Feedback';
import { Unless } from 'src/components/helpers';
import { MetaTags } from 'src/components/MetaTags';
import { MainNavigationBar } from 'src/components-v2/MainNavigationBar';

// contexts
import { AnalyticsProvider, LocaleContext } from 'src/contexts';
import { CmsThemeContextProvider } from 'src/contexts/CmsThemeContext/CmsThemeContextProvider';

// helpers
import { getCurrentPage } from 'src/lib/utils';
import { CmsThemes, Locales } from 'src/lib/constants';
import { PLAID_ENV } from 'src/env';

// data
import { noJSBannerData } from 'src/store/banners';

const ForeignContentNotification = dynamic(
  () => {
    return import(
      'src/components/Feedback/ForeignContentNotification/ForeignContentNotification.tsx'
    );
  },
  {
    ssr: false,
  },
);

// For CMS pages
const TemplateWrapper = ({
  children,
  className = '',
  locale = '',
  cmsTheme = 'web 2.0',
  ...props
}) => {
  const { asPath } = useRouter();

  return (
    <CmsThemeContextProvider cmsTheme={cmsTheme}>
      <AnalyticsProvider>
        <LocaleContext.Provider value={locale || Locales.EN_US}>
          <div className={className}>
            <MetaTags {...props} />
            <div className='banner-container'>
              <noscript>
                <Banner {...noJSBannerData} />
              </noscript>
            </div>

            {cmsTheme === CmsThemes.WEB3 ? (
              <MainNavigationBar />
            ) : locale === Locales.EN_GB || locale === Locales.EN_EU ? (
              <>
                <MainNav {...props} />
                <ForeignContentNotification />
              </>
            ) : (
              <MainMenu />
            )}

            <div
              id='main-content'
              className={`${getCurrentPage(asPath) || 'homepage'} default`}
              data-env={PLAID_ENV}
            >
              {children}
            </div>
            <Unless condition={props && props['exclude-footer']}>
              <Footer {...props} />
            </Unless>
            <ConsentManager />
            <UnidentifiedTraffic />
          </div>
          </LocaleContext.Provider>
        </AnalyticsProvider>
    </CmsThemeContextProvider>
  );
};

export default TemplateWrapper;
