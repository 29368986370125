import React, { FC } from 'react';
import Image from 'src/components/Image';
import { ContactForm } from 'src/components/Forms';
import DefaultTemplate from 'src/templates/default';

import { Locales } from 'src/lib/constants';
import { dangerous_getStaticData } from 'src/lib/getStaticData';

interface Props {
  locale?: string;
}

const Contact: FC<Props> = ({ locale = Locales.EN_US }) => {
  const { metaData, header, subheader } = dangerous_getStaticData({
    locale,
    filePath: 'contact',
  });

  return (
    <DefaultTemplate {...metaData} locale={locale} cmsTheme='web 3.0'>
      <div className='nav-spacer' />

      <section className='background-page-wrapper'>
        <div className='grid-container section-container'>
          <div className='grid-x grid-margin-x'>
            <div className='cell medium-6'>
              <h2 className='section-header'>{header}</h2>
            </div>
          </div>

          <div className='grid-x grid-margin-x contact-form-grid'>
            <div className='cell small-12 large-auto'>
              <p className='regular section-subheader'>{subheader}</p>
              <ContactForm />
            </div>

            <div className='cell small-12 medium-6 large-auto'>
              <div className='contact-form__image-wrapper'>
                <Image
                  isLazy={false}
                  src='https://images.ctfassets.net/ss5kfr270og3/18SM1MGg2TfN2I9I782xeH/8f716b72ed723d3238ebbaa34cb74adf/Contact_Hero_Image_2x.png?q=70&fm=webp'
                  alt='contacts bacground image'
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </DefaultTemplate>
  );
};
Contact.displayName = 'Contact';
export default Contact;
