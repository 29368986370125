import React from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { generateArticleStructuredData } from 'src/lib/utils';

// components
import { TemplateWrapper } from 'src/templates';
import { CmsComponentSwitch } from 'src/cms/CmsComponentSwitch';
import { Header, Heading } from 'src/components/Blog';
import { Tag } from 'src/components/DataDisplay';
import { ContentfulToolbar } from 'src/components/ContentfulToolbar';

// helpers
import { TemplateFieldShape } from 'src/lib/prop-types';
import { ContentfulTemplates } from 'src/lib/constants';

// styles
import styles from './BlogPostTemplate.module.scss';

const BlogPostScripts = dynamic(
  () => {
    return import('src/templates/BlogPostTemplate/BlogPostTemplateScripts');
  },
  {
    ssr: false,
  },
);

const propTypes = {
  fields: TemplateFieldShape.isRequired,
};

const BlogPostTemplate = ({
  fields: { published, updated, ...fields },
  ...props
}) => {
  const { route } = useRouter();

  const authors = fields?.authors?.map((author) => {
    return author?.fields?.name;
  });
  return (
    <TemplateWrapper
      meta-title={fields?.metaTitle}
      meta-description={fields?.metaDescription}
      hasFooterCta={fields?.hasFooterCta}
      openGraphImage={fields?.openGraphImage?.fields?.file?.url}
      tags={fields?.tags?.map((tag) => {
        return tag?.fields?.tag;
      })}
      authors={authors}
      published={published}
      updated={updated}
      className='blog'
      structuredData={generateArticleStructuredData({
        authorName: authors?.join('; '),
        datePublished: published,
        dateModified: updated || published,
        headline: fields?.metaTitle,
        image: fields.bannerImage?.fields?.file?.url,
      })}
    >
      <article>
        <Header
          src={fields?.bannerImage?.fields?.file?.url}
          alt={fields?.bannerImage?.fields?.description}
        />
        <div className={styles.wrapper}>
          <Heading
            date={published}
            updated={updated}
            title={fields?.metaTitle}
            authors={authors}
            location={fields?.pageSlug}
          />
          <div
            className={styles.body}
            data-testid={ContentfulTemplates.BLOG_POST}
          >
            <CmsComponentSwitch
              templatePage={ContentfulTemplates.BLOG_POST}
              components={fields?.components}
            />
          </div>
          <div>
            {fields?.tags?.map((tag) => {
              return (
                <Tag
                  key={tag?.fields?.tag}
                  tag={tag?.fields?.tag?.split('-').join(' ')}
                  href={`/blog/tag/${tag?.fields?.tag}/`}
                />
              );
            })}
          </div>
        </div>
      </article>
      <BlogPostScripts route={route} {...props} />
      <ContentfulToolbar />
    </TemplateWrapper>
  );
};
BlogPostTemplate.propTypes = propTypes;

export default BlogPostTemplate;
