import React, { ReactElement } from 'react';
import Image from 'src/components/Image';

import DefaultTemplate from 'src/templates/default';

import { Locales } from 'src/lib/constants';
import { mutateHardCodedLinks } from 'src/lib/utils';

import * as enUs500 from 'src/store/500';
import * as enEu500 from 'src/store/en-eu/500';
import * as enGb500 from 'src/store/en-gb/500';

import { ERROR_EVENTS } from 'src/lib/trackingEvents';
import { useTrack } from 'src/contexts/AnalyticsContext';
// data
const error500PerLocale = {
  [Locales.EN_US]: enUs500,
  [Locales.EN_EU]: enEu500,
  [Locales.EN_GB]: enGb500,
};

const Error500 = ({
  locale = Locales.EN_US,
}: {
  locale?: string;
}): ReactElement => {
  const {
    metaData,
    body: { header = '', description = '' },
  } = mutateHardCodedLinks({
    data: error500PerLocale[locale] || error500PerLocale[Locales.EN_US],
    locale,
  });

  const track = useTrack();

  React.useEffect(() => {
    // this really isn't the right tool for this kind of thing, but it's all we have right now
    track({ type: ERROR_EVENTS.ERROR_500 });
  }, [track]);

  return (
    <DefaultTemplate {...metaData} noIndex={true} locale={locale}>
      <div className='grid-container section-container section-container--center-content-mobile'>
        <div className='grid-x grid-margin-x contact-form-grid'>
          <div className='cell medium-2'></div>
          <div className='cell medium-4'>
            <h2
              className='half-section-padding-bottom--mobile-hide'
              dangerouslySetInnerHTML={{ __html: header }}
            />
            <h4 className='regular'>{description}</h4>
          </div>
          <div className='cell medium-4'>
            <Image
              src='/assets/img/backgrounds/500-error-state.png'
              alt='500 error'
            />
          </div>
          <div className='cell medium-2'></div>
        </div>
      </div>
    </DefaultTemplate>
  );
};

export default Error500;
